window.addEventListener("DOMContentLoaded", function () {
  document.querySelector("#show-menu").addEventListener("click", (e) => {
    e.preventDefault();
    const hiddenItems = document.querySelectorAll(".hidden-menu");
    for (let i = 0; i < hiddenItems.length; i++) {
      const item = hiddenItems[i];
      if (item.classList.contains("hidden")) {
        item.classList.remove("hidden");
      } else {
        item.classList.add("hidden");
      }
    }
  });

  const anchors = document.querySelectorAll(".dropdown-anchor > button");
  for (let i = 0; i < anchors.length; i++) {
    const anchor = anchors[i];
    anchor.addEventListener("click", (e) => {
      e.preventDefault();

      const menu = anchor.parentElement.querySelector(".dropdown-menu");
      if (menu.classList.contains("hidden")) {
        menu.classList.remove("hidden");
      } else {
        menu.classList.add("hidden");
      }
    });
  }
});
