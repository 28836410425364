window.addEventListener("DOMContentLoaded", function () {
  if (
    window.tlac_instance !== "texas" &&
    location.pathname !== "/cookie-policy"
  ) {
    cookieconsent.initialise({
      type: "info",
      position: "top-left",
      palette: {
        popup: { background: "#000" },
        button: { background: "#f8c317", padding: "5px 25px" },
      },
      content: {
        message:
          "This website uses cookies to improve your experience. We need them to remember user sessions and analyze website use.",
        allow: "Allow",
        link: "Learn more",
        href: "/cookie-policy",
      },
      cookie: { domain: window.location.hostname },
    });
  }
});
