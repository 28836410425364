document.addEventListener("click", (e) => {
  if (e.target.classList.contains("dismiss-alert")) {
    e.preventDefault();
    let el = e.target.parentElement;
    while (!el.classList.contains("alert")) {
      el = el.parentElement;
    }
    if (el.classList.contains("alert")) {
      el.parentElement.removeChild(el);
    }
  }
});
