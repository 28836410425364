import Cookies from "js-cookie";

export default class Dashboard {
  index() {
    document.addEventListener(
      "click",
      function (event) {
        if (!event.target.matches(".dismiss-intro")) return;
        event.preventDefault();

        const intro = document.getElementById("dashboard-intro");
        intro.parentNode.removeChild(intro);
        Cookies.remove("show_intro");
      },
      false
    );
  }
}
