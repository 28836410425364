import "cookieconsent";
import "core-js/stable";
import Punchbox from "punchbox-js";
import Rails from "rails-ujs";
import ReactRailsUJS from "react_ujs";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import Dashboard from "../controllers/dashboard";
import Static from "../controllers/static";
import Trainings from "../controllers/trainings";
import "../cookiepolicy";
import "../flash";
import "../menu";
import "../stylesheets/application.scss";

Rails.start();
ReactRailsUJS.useContext(require.context("components", true));
Punchbox.on("Static", Static);
Punchbox.on("Trainings", Trainings);
Punchbox.on("Dashboard", Dashboard);
